import React from "react";
import "cross-fetch/polyfill";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import root from "window-or-global";
import {onError} from "apollo-link-error";
import Layout from "./section/layout";
import SEO from "../components/section/seo";
import {IntlProvider} from "react-intl";
import lang from "./locale/en";

import "../style/leaflet.scss";
import "../style/index.scss";
import "../style/menu.scss";
import "../style/animation.scss";
import "../style/portal.scss";
import "../style/product-display.scss";
import "../style/register.scss";
import "../style/login.scss";

import "../style/join-us.scss";
import "../style/portal.scss";
import "../style/header-login.scss";

const apolloState = root.__APOLLO_STATE__;//eslint-disable-line

const errorHandler = onError((op) => {
  const {response, graphQLErrors, networkError} = op;
  console.log("error", {meta: {graphQLErrors, networkError}, response}); //eslint-disable-line
});

const api = `${process.env.BACKEND_URL}graphql.api`;

const authLink = new ApolloLink(function(operation, forward) {
  console.log("authLink", arguments);
  const token = root?.localStorage?.getItem("authToken");
  if (token) {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  }
  return forward(operation);
});

function merge(existing, incoming) {
  return {
    ...existing,
    ...incoming,
  };
}

const cacheSettings = {
  typePolicies: {
    QueryClassMethods: {
      merge(existing = {}, incoming) {
        const output = {
          ...existing,
        };
        Object.keys(incoming).forEach((k) => {
          if (existing[k] && k !== "__typename") {
            output[k] = merge(existing[k], incoming[k]);
          } else {
            output[k] = incoming[k];
          }
        });
        return output;
      },
    },
    QueryModels: {
      merge(existing = {}, incoming) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
    MutationClassMethods: {
      merge(existing = {}, incoming) {
        const output = {
          ...existing,
        };
        Object.keys(incoming).forEach((k) => {
          if (existing[k] && k !== "__typename") {
            output[k] = merge(existing[k], incoming[k]);
          } else {
            output[k] = incoming[k];
          }
        });
        return output;
      },
    },
    MutationModels: {
      merge(existing = {}, incoming) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
  },
};
const cache = new InMemoryCache(cacheSettings);

export const client = new ApolloClient({
  credentials: "include",
  // uri: api,
  // shouldBatch: true,
  // cache: apolloState ? new InMemoryCache().restore(apolloState) : new InMemoryCache(),
  cache,
  link: errorHandler.concat(authLink.concat(new HttpLink({
    uri: api,
  }))),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "cache-first",
    },
  },
});


export default function Manager(props) {
  return (<>
    <ApolloProvider client={client}>
      <IntlProvider messages={lang} locale={(root?.navigator?.language || "en")}>
        <Layout>
          <SEO title="Evergy - Internet Provider">
            <meta name="description" content={`Evergy - ${props.pageDescription || ""}`}/>
          </SEO>
          {props.children}
        </Layout>
      </IntlProvider>
    </ApolloProvider>
  </>
  );
}
