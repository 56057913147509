/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
// import {useStaticQuery, graphql} from "gatsby";
import "../../style/index.scss";
const Layout = ({title, children}) => {
  // const data = useStaticQuery(graphql`
  //    query SiteTitleQuery {
  //      site {
  //        siteMetadata {
  //          title
  //        }
  //      }
  //    }
  //  `);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} title={title} /> */}
      {/* <script src="/eCrypt.min.js"/> */}
      <script src="https://secure.ewaypayments.com/scripts/eCrypt.min.js" />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
