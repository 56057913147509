export default {
  "nav.home": "Home",
  "nav.register": "Join Us",
  "nav.support": "Help",
  "nav.contact": "Contact Us",
  "nav.login": "Login",
  "placeholders.streetNo": "Street Number",
  "placeholders.streetAddress": "Street Address",
  "placeholders.suburb": "Suburb/City",
  "placeholders.state": "State",
  "placeholders.postCode": "Post Code",
  "placeholders.building": "Building",
  "placeholders.firstName": "First Name",
  "placeholders.lastName": "Last Name",
  "placeholders.email": "Email Address",
  "placeholders.mobileNumber": "Phone Number",
  "placeholders.mobileNumberVerification": "Verification Code",
  "placeholders.coupon": "Voucher Code",
  "placeholders.cardNumber": "Credit Card Number",
  "placeholders.cardExpiry": "Credit Card Expiry",
  "placeholders.cardExpiry.month": "Credit Card Expiry Month",
  "placeholders.cardExpiry.year": "Credit Card Expiry Year",
  "placeholders.cardCCV": "Credit Card CCV",
  "placeholders.cardName": "Credit Card Name",
  "placeholders.captcha": "Captcha Code",
  "placeholders.address.unit": "Unit / Building",
  "placeholders.address.streetNo": "Street Number",
  "placeholders.address.streetAddr": "Street Address",
  "placeholders.address.city": "City / Suburb",
  "placeholders.address.state": "State",
  "placeholders.address.postcode": "Postcode",
  "placeholders.address.country": "Country",
  "placeholders.device.macAddress": "Device's Mac Address",
  "placeholders.userName": "Username",
  "placeholders.password": "Password",
  "buttons.skip": "Skip",
  "buttons.add": "Add me",
  "buttons.signup": "Sign up",
  "buttons.building-enquiry": "Building Enquiry",
  "buttons.signupnow": "Sign up now",
  "buttons.select-product": "Select me",
  "buttons.confirm": "OK. I've got it",
  "buttons.register-submit": "Sign me up",
  "buttons.login": "Log me in",
  "buttons.offers": "More Offers",
  "buttons.login.short": "Log in",
  "buttons.failure": "< Oh No",
  "buttons.landing": "Let's get started",
  "buttons.save": "Save",
  "buttons.cancel": "Cancel",
  "buttons.usage": "Usage",
  "buttons.remove": "Remove",
  "buttons.plan.current": "Current Plan",
  "buttons.plan.previous": "Previous Plan",
  "buttons.apply": "Apply",
  "buttons.reset": "Reset",
  "buttons.confirm1": "Confirm",
  "buttons.paymentDetails.remove": "Remove Payment Details",
  "buttons.plan.cancel": "Cancel Plan",
  "buttons.purchase": "Purchase",
  "buttons.purchase.now": "Change now",
  "buttons.purchase.billingCycle": "Change at billing cycle",
  "buttons.portal": "Go to my Portal",
  "buttons.close": "Close",
  "buttons.forgot": "Forgot your password?",
  "buttons.checkAddress": "Check address",
  "buttons.request": "Request",
  "validation.errors.maxLength2": "You can not type in more than 2 characters",
  "validation.errors.maxLength3": "You can not type in more than 3 characters",
  "validation.errors.maxLength4": "You can not type in more than 4 characters",
  "validation.errors.maxLength16": "You can not type in more than 16 characters",
  "validation.errors.maxLength50": "You can not type in more than 50 characters",
  "validation.errors.maxLength100": "You can not type in more than 100 characters",
  "validation.errors.minLength2": "You can only enter in 2 digits",
  "validation.errors.minLength4": "You can only enter in 4 digits",
  "validation.errors.minLength9": "You can only enter in 9 digits",
  "validation.errors.minLength16": "You can only enter in 16 digits",
  "validation.errors.required.firstName": "Enter your first name",
  "validation.errors.required.lastName": "Enter your last name",
  "validation.errors.required.email": "Enter your email address",
  "validation.errors.required.mobileNumber": "Enter your mobile number",
  "validation.errors.required.mobileNumberVerification": "Enter your verification code",
  "validation.errors.required.coupon": "Enter your voucher code",
  "validation.errors.required.cardName": "Enter the name on your credit card",
  "validation.errors.isAlpha": "You can only enter in alpha characters",
  "validation.errors.isEmail": "Enter a valid email address",
  "validation.errors.isAlphanumeric": "You can only enter in alpha numerical characters",
  "validation.errors.isNumeric": "You can only enter in numerical characters",
  "validation.errors.required.oldPassword": "You have forgotten to enter your old password",
  "validation.errors.required.password": "You have forgotten to enter your password",
  "validation.errors.matchRegExp.password": "So it is as secure as possible, your password needs to have a mix of letters and numbers and at least 8 characters",
  "validation.errors.equalsField.password": "Please ensure you have retyped the password correctly",
  "validation.errors.required.address.unit": "Enter your unit / building",
  "validation.errors.required.address.streetNo": "Enter your street number",
  "validation.errors.required.address.streetAddr": "Enter your street address",
  "validation.errors.required.address.city": "Enter your city",
  "validation.errors.required.address.state": "Enter your state",
  "validation.errors.required.address.postcode": "Enter your postcode",
  "validation.errors.required.address.country": "Enter your country",
  "validation.errors.year": "Year must be in full year format e.g. 2016",
  "validation.errors.month": "Month is required e.g. 01-12",
  "validation.errors.card.number": "Enter your card number",
  "validation.errors.card.name": "Enter your card name",
  "validation.errors.card.ccv": "Enter your card CCV",
  "validation.errors.card.expiry.month": "Enter your card month expiry",
  "validation.errors.card.expiry.year": "Enter your card year expiry",
  "validation.errors.required.userName": "Enter your username",
  "home.welcome.title": "Lightning Fast Internet",
  "home.welcome.banner.line1": "For residential apartments, student accommodation and retail precincts.",
  "home.welcome.subtext": "Life's too short to experience anything else",
  "home.unlimited.title": "Unlimited Internet",
  "home.unlimited.banner": "only $59 per month",
  "home.unlimited.subtext": "(You spend more than this on an average Saturday don't you??)",
  "home.included.title": "As Well As",
  "home.included.banner": "Speed, no additional sign up charges or download costs, and lock in contracts. VostroNet makes getting online easier than ever before.",
  "home.included.subtext.item1": "Want more details?",
  "home.whois.0.date": "OCT 14'",
  "home.whois.0.subtitle": "VostroNet Starts",
  "home.whois.1.date": "NOV 14'",
  "home.whois.1.subtitle": "First user comes online",
  "home.whois.2.date": "APR 15'",
  "home.whois.2.subtitle": "VostroNet launches in Sydney with its first site",
  "home.whois.3.date": "JUL 15'",
  "home.whois.3.subtitle": "First 1,000+ devices",
  "home.whois.4.date": "MAR 16'",
  "home.whois.4.subtitle": "VostroNet launches in Brisbane with its first site",
  "home.whois.5.date": "APR 16'",
  "home.whois.5.subtitle": "VostroNet launches in Auckland",
  "home.whois.6.date": "MAY 16'",
  "home.whois.6.subtitle": "Melbourne site comes online",
  "home.whois.7.date": "JUL 16'",
  "home.whois.7.subtitle": "VostroNet celebrates 15 live sites",
  "home.whois.8.date": "SEP 16'",
  "home.whois.8.subtitle": "15,000+ devices online",
  "home.whois.9.date": "JAN 17'",
  "home.whois.9.subtitle": "All sites upgraded to offer wireless",
  "home.whois.10.date": "FEB 17'",
  "home.whois.10.subtitle": "Additional 2500 users added to the network",
  "home.like.title": "LIKE WHAT YOU SEE?",
  "home.like.banner": "Let's get to know each other better",
  "home.footer.privacy": "Privacy Policy",
  "home.footer.support": "Help",
  "home.footer.terms": "General Terms & Conditions",
  "home.footer.critical": "Critical Information Summary",
  "home.footer.knowledge": "Knowledge Base",
  "home.footer.status": "Status",
  "home.bottom-footer": "Copyright © 2017 VostroNet ",
  "register.steps.1": "Step 1: ",
  "register.steps.2": "Step 2: ",
  "register.steps.3": "Step 3: ",
  "register.steps.4": "Step 4: ",
  "register.steps.5": "Step 5: ",
  "register.steps.6": "Step 6: ",
  "register.steps.7": "Step 7: ",
  "register.landing.header": "BOOYAH!!",
  "register.landing.banner.line1": "So you're ready to go... wicked",
  "register.landing.banner.line2": "So are we, let's do this",
  "register.landing.subtext": "In just 5 easy steps",
  "register.landing.productFailed.title": "Product retrieval failed",
  "register.landing.productFailed.message": "An error occurred. Couldn't retrieve available products",
  "register.coupon.title": "Got a voucher?",
  "register.plan.title": "Choose your plan",
  "register.products.data.limited": "{size} GB data plan",
  "register.products.data.unlimited": "Unlimited data",
  "register.products.rate": "Up to {download}/{upload} Mbps",
  "register.products.rate2": "Up to {download} Mbps",
  "register.products.renewal.devicelimit": "For up to {deviceLimit}x devices",
  "register.products.renewal.day": "per day",
  "register.products.renewal.day2": "/day",
  "register.products.renewal.month": "per month",
  "register.products.renewal.month2": "/month",
  "register.products.back": "oops! voucher didn't work?",
  "register.upgrade.title": "Pimp my plan",
  "register.upgrade.subtitle.line1": "NEED TO GO FAST?",
  "register.upgrade.subtitle.line2": "",
  "register.upgrade.speed": "Move to a faster Internet experience",
  "register.upgrade.addon": "Upgrade to {upload}/{download} for ${value} per month",
  "register.review.title": "Let's Review",
  "register.review.subtitle": "YOUR PLAN",
  "register.review.baseplan.limited": "${value} {size} GB data plan {renewal}",
  "register.review.baseplan": "${value} {renewal} UNLIMITED",
  "register.review.upgraded": "${value} {renewal} UPTO {upload}/{download} UPGRADE",
  "register.review.total": "The plan is month to month and automatically renewed. Total minimum cost is ${value} inc. GST. ",
  "register.review.critical": "Click here to view the critical information summary.",
  "register.review.back": "Want to change something?",
  // "register.review.subtotal": "${value} {renewal} RR",
  "register.details.title": "Your Details",
  "register.mobile.countdown": "TXT arriving in {seconds}",
  "register.mobile.countdown.finish": "Still not there? Press 'Resend'",
  "register.userDetails.verification.bad": "oops! wrong number?",
  "register.userDetails.keyPressEnter": "Press ENTER",
  "register.userDetails.buttonResend": "Resend",
  "register.userDetails.buttonConfirm": "Next",
  "register.userDetails.buttonReset": "Reset",
  "register.userDetails.name.title": "Let's start! What's your name?",
  "register.address.title": "What's your address?",
  "register.shipping-address.title": "Your shipping address?",
  "register.userDetails.email.title": "That's great {firstName}. Now what’s your email address?",
  "register.userDetails.mobileNumber.title": "how about your mobile number?",
  "register.userDetails.verification.title": "Great! We've sent a 6-digit verification code to +{dialCode}{mobileNo}",
  "register.userDetails.cardNumber.title": "That worked! Now for your Credit Card number?",
  "register.userDetails.cardName.title": "What's the name on the Card?",
  "register.userDetails.cardExpiry.title": "What's the expiry date on the Card?",
  "register.userDetails.cardCCV.title": "And the 3-digit CCV number on the back?",
  "register.final.addons": "Selected Addons",
  "register.final.optional.hardware": "Optional Hardware",
  "register.final.name": "Name",
  "register.final.address": "Address",
  "register.final.shipping-address": "Shipping Address",
  "register.final.email": "Email",
  "register.final.mobileNumber": "Mobile",
  "register.final.cardName": "Card Name",
  "register.final.cardNumber": "Card Number",
  "register.final.cardExpiry": "Expiry",
  "register.final.cardCCV": "CCV",
  "register.final.edit": "edit",
  "register.final.review": "Confirm your details are correct below",
  "register.final.processing": "Processing your request.",
  "register.final.username": "Username: ",
  "register.final.password": "Password: ",
  "register.final.congratulations": "Congratulations!",
  "register.final.success-message": "Your account has been created successfully",
  "register.final.success-message.subtitle": "Record the following information in safe location.",
  "register.final.failure-message": "An Error has occurred, Contact the support team.",
  "register.final.costbrief.line1": "Note: ",
  "register.final.costbrief.line2": "You will be charged ${value} automatically {renewal} until cancelled. ",
  "register.final.acknowledge": "I acknowledge I will be charged ${value} automatically {renewal} until cancelled.",
  "register.final.accepttncs.line1": "I have read and agree to the {tnclink} and {criticallink}.",
  "register.final.accepttncs.tnc": "Evergy General Terms and Conditions",
  "register.final.accepttncs.critical": "Critical Information Summary",
  "register.final.failure-message.error": "{message}",
  "register.final.captcha": "Please re-enter captcha for validation",
  "register.final.order-id": "Order Id: ",
  "register.final.subtext": "We will be in contact shortly to finalise the onboarding process",
  "register.outside.header": "Outside of VostroNet",
  "register.outside.banner.line1": "We have detected that you are accessing this page ",
  "register.outside.banner.line2": "from outside the VostroNet Network",
  "register.outside.subtext": "A VostroNet Internet Plan will only work inside an existing VostroNet enabled building",
  "buttons.outside": "Continue >",
  "portal.callout.renewal.assignPlan": "Click here to assign a new plan.",
  "portal.callout.renewal.inactive.title": "There is a problem with your account",
  "portal.callout.renewal.inactive.message": "You do not have an active plan renewal, your internet will be disconnected at the end of your current plan. ",
  "portal.callout.renewal.cancelling.title": "Your account has been scheduled for termination",
  "portal.callout.renewal.cancelling.message": "Your account has been scheduled for termination, your internet will be disconnected at the end of your current plan. ",
  "portal.menu.home": "Home",
  "portal.menu.details": "Your Information",
  "portal.menu.plan": "Your Plan",
  "portal.menu.devices": "Your Devices",
  "portal.menu.password": "Change Your Password",
  "portal.menu.logout": "Logout",
  "portal.home.plan.inactive": "No Active Plan",
  "portal.home.plan.title": "Plan Information",
  "portal.home.plan.unlimited": "∞",
  "portal.home.plan.label.usage": "You have used {usageTotal}Gb of {planTotal}Gb",
  "portal.home.plan.label.remaining": "Data Remaining",
  "portal.home.plan.label.consumed": "Data Consumed",
  "portal.home.period.title": "Days Remaining",
  "portal.home.period.message": "Your plan ends at {end}",
  "portal.home.wifi.title": "Wifi Installer",
  "portal.password.title": "Change Your Password",
  "portal.password.message": "Enter your Old and New Passwords below",
  "portal.password.placeholder.old": "Old Password",
  "portal.password.placeholder.new": "Password",
  "portal.password.placeholder.new1": "Confirm Your Password",
  "portal.password.success": "Your password has been changed.",
  "portal.details.success": "Your account details have been saved.",
  "portal.details.homeAddress.title": "Your Service Address",
  "portal.details.homeAddress.subtitle": "Enter the Address where you will access VostroNet from",
  "portal.details.billingAddress.title": "Your Billing Address",
  "portal.details.billingAddress.subtitle": "Where are your invoices sent?",
  "portal.details.billingAddress": "My service address is not my billing address.",
  "portal.details.title": "Your Personal Information",
  "portal.details.invalidEmail": "Valid email address required",
  "portal.details.subtitle": "Enter the following Profile information",
  "portal.devices.none": "No Devices Found",
  "portal.devices.user.title": "Devices - You have used {total} out of {limit} Devices",
  "portal.devices.user.label.device": "Device",
  "portal.devices.user.label.ip4": "IP4 Address",
  "portal.devices.add.title": "Add New Device",
  "portal.devices.add.subtitle": "Manually add your device to your account.",
  "portal.devices.usage.title": "Download {device} usage",
  "portal.devices.usage.message": "Please choose from which plan period would you like to download.",
  "portal.plan.coupon": "Coupon Entry",
  "portal.plan.title": "Your Plan",
  "portal.plan.cancellation.title": "Confirm Plan Cancellation",
  "portal.plan.cancellation.message": "Are you sure you want to cancel the automatic monthly renewal of your plan?",
  "portal.plan.change.title": "Confirm Plan Change",
  "portal.plan.change.message": "Select when you like the plan change to occur.",
  "portal.plan.new.title": "Confirm Your New Plan",
  "portal.plan.new.message": "You will be charged ${total} every billing cycle starting immediately. Your billing cycle will be reset, with any unused data lost.",
  "portal.plan.error.title": "An Error has occurred, please review the following.",
  "portal.plan.error.message": "{message}",
  "portal.plan.failed.title": "Welcome to VostroNet - Failed Transaction",
  "portal.plan.failed.message": "Your VostroNet Account has been successfully created but there was an issue with finalising your transaction, please review your credit card and try again.",
  "portal.plan.success": "Your changes have been saved.",
  "portal.plan.cancel.title": "Cancel Your Plan",
  "portal.plan.cancel.message": "To cancel your plan from renewing, please click on the button below.",
  "portal.plan.paymentDetails.remove.title": "Remove Payment Details",
  "portal.plan.paymentDetails.remove.message": "This will remove your payment details from this account and prevent the system from processing further payments.",
  "portal.plan.paymentDetails.confirm.title": "Confirm removal of your payment details",
  "portal.plan.paymentDetails.confirm.message": "Are you sure you want to remove your payment details?",
  "portal.plan.details.title": "Plan Details",
  "portal.plan.details.criticalSummary1": "Critical Summary available ",
  "portal.plan.details.criticalSummary2": "here",
  "portal.plan.details.total": ". Total min. cost ${total}inc gst",
  "portal.paymentDetails.title": "Your Payment Details",
  "portal.paymentDetails.message": "Enter the Payment Details for the service",
  "portal.plan.products.plan.title": "Choose your Data",
  "portal.plan.products.plan.subtitle1": "Please select a plan",
  "portal.plan.products.plan.subtitle2": "${total} {length}",
  "portal.plan.products.plan.message1": "Choose how much data you want on your plan. Big downloader? No worries select the a bigger plan. ",
  "portal.plan.products.plan.message2": "Check out this guide",
  "portal.plan.products.plan.message3": " if you are unsure.",
  "portal.plan.products.plan.validation": "Please select your plan speed.",
  "portal.plan.products.rate.title": "Choose a Speed Pack",
  "portal.plan.products.rate.subtitle1": "Please select a speed",
  "portal.plan.products.rate.subtitle2": "Included",
  "portal.plan.products.rate.subtitle3": "${total} {length}",
  "portal.plan.products.rate.message": "Actual speeds may be slower and will vary due to a number of factors including hardware and software configuration and internet traffic.",
  "portal.plan.products.rate.validation": "Please select your preferred line speed.",
  "login.title": "LOGIN",
  "login.subtitle": "Login with your Username to access the VostroNet portal",
  "login.join.title": "JOIN VOSTRONET",
  "login.join.subtitle": "With VostroNet you can access the Internet and do stuff",
  "login.forgot.title": "Forgot your username or password?",
  "login.forgot.message": "Enter your Email Address and Captcha code. A new password will be sent to your registered mobile device.",
  "login.system-notification": "System Notification",
  "login.request.submit": "Your request has been submitted",
  "login.account.error": "There is a problem with your account.",
  "login.deviceLimit": "You have too many devices associated to your account. Confirm your devices and try again. ",
  "login.inactive": "Your account has been disabled. You will be unable to access the Internet until your plan is enabled. ",
  "login.dataLimit.title": "Your data limit has been reached.",
  "login.dataLimit.message": "You have reached the data limit associated with your plan.  Login to upgrade or continue at a reduced speed. ",
  "error.existingEmail": "Email is already registered",
  "error.noBuilding": "Building not found",
  "error.noBuildingAddress": "Building address not found",
  "error.invalidVoucher": "Invalid voucher",
  "error.noService": "Service Unavailable",
  "error.serviceActive": "Service is active",
  "error.verificationCode": "Invalid verification code",
  "error.invalidCard": "Invalid card number",
  "error.properEmail": "Please use a proper email",
  "error.unknown": "An error has occured",
  "error.invalidCaptcha": "Invalid Captcha",
  "error.invalidPlans": "Invalid Plans",
  "error.noAccount": "You need to update your credit card details",
  "error.invalidProducts": "Selected product is unavailable",
};
